<template>
  <div>
    <div class="container-fluid pt-7 pb-5">
      <el-collapse class="expand-main mb-3 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Name"
                    :label="$t.translate('FRM_NAME')"
                    input-classes="form-control-alternative"
                  />
                </div>

                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Email"
                    :label="$t.translate('FRM_EMAIL')"
                    input-classes="form-control-alternative"
                  />
                </div>
              </div>
              <div class="row mt-1 mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="filterAction">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                  <el-button type="primary" class="ml-3" @click="resetParams">
                    {{ $t.translate("BTN_RESET") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>

      <div class="d-flex justify-content-end">
        <el-button
          v-if="$h.hasRoles(['CLIENT', 'ADMIN', 'SUB_ADMIN', 'BUSINESS'])"
          type="primary"
          class="mb-3"
          @click="addUser"
        >
          {{ $t.translate("BTN_INVITE") }}
        </el-button>
      </div>

      <div class="shadow bg-white p-around">
        <Table
          url="/user/list"
          :query="{ ...Filter }"
          ref="filttable"
          :default-sort="{ prop: Filter.SortBy, order: Filter.SortOrder }"
          @sort-change="sortChange"
          height="68vh"
        >
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Name"
            :label="$t.translate('FRM_NAME')"
            min-width="182"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ $h.getFullName(scope.row) }}
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="Email"
            :label="$t.translate('FRM_EMAIL')"
            min-width="190"
          ></el-table-column>

          <el-table-column :label="$t.translate('LBL_STATUS')" min-width="100">
            <template #header>
              <TableFilter
                :handleSelection="
                  (arr) => $refs.filttable.currentSelection(arr, 'UserStatusId')
                "
                :hasSelection="true"
                :fieldName="$t.translate('LBL_STATUS')"
                url="envconf/UserStatusOpts"
                fieldKey="value"
                fieldLabel="label"
              />
            </template>

            <template #default="scope">
              <el-tag
                :type="scope.row.UserStatusCode === 'ACTIVE' ? 'primary' : 'info'"
                disable-transitions
                >{{ $t.translate(`LBL_USER_${scope.row.UserStatusCode}`) }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="LastCreated"
            :label="$t.translate('FRM_LAST_CREATED')"
            width="210"
          >
            <template #default="scope">
              {{ $h.formatDateTime(scope.row.LastCreated) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_ACTION')"
            width="70"
            fixed="right"
            class-name="pl-1 action-col"
          >
            <template #default="scope">
              <el-dropdown
                trigger="click"
                :hide-on-click="true"
                @command="(comd) => handleOptions(scope.row, comd, $refs)"
              >
                <el-button type="primary" size="mini">
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      :disabled="
                        !$h.hasRoles(['CLIENT', 'ADMIN', 'SUB_ADMIN', 'BUSINESS'])
                      "
                      command="edit"
                      >{{ $t.translate("BTN_EDIT") }}</el-dropdown-item
                    >

                    <el-dropdown-item
                      :disabled="!$h.hasRoles(['CLIENT', 'ADMIN', 'SUB_ADMIN'])"
                      command="remove"
                      >{{ $t.translate("BTN_DELETE") }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>

  <ExportModal :types="['EXCEL']" ref="export" />

  <modal v-model:show="showModal">
    <template #header>
      <h2 id="invModalLabel" class="modal-title text-dark">
        {{ $t.translate("TTL_SUB_CLIENT_INFO") }}
      </h2>
    </template>
    <div class="disc-content mt--4">
      <div class="row">
        <div class="col-lg-6">
          <label class="form-control-label">{{ $t.translate("FRM_EMAIL") }}</label>
          <base-input
            v-model="inviteData.Email"
            :valid="!$h.isBlank(inviteData.Email) && $h.validateEmail(inviteData.Email)"
            type="email"
            form-classes="mb-2"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label class="form-control-label">{{ $t.translate("FRM_FIRSTNAME") }}</label>
          <base-input
            v-model="inviteData.FirstName"
            :valid="!$h.isBlank(inviteData.FirstName)"
            form-classes="mb-2"
          />
        </div>
        <div class="col-lg-6">
          <label class="form-control-label">{{ $t.translate("FRM_LASTNAME") }}</label>
          <base-input
            v-model="inviteData.LastName"
            :valid="!$h.isBlank(inviteData.LastName)"
            form-classes="mb-2"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <el-button type="primary" @click="inviteClient">{{
        $t.translate("BTN_SEND_INV")
      }}</el-button>
    </template>
  </modal>
</template>
<script>
import ExportModal from "@/components/ExportModal";
import Table from "@/components/Table.vue";
import TableFilter from "@/components/TableFilter.vue";
import { reactive, onMounted, inject, ref } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { translation } from "@/utils/translation";
import { router } from "@/utils/router";
import { helpers } from "@/utils/helpers";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  components: { Table, TableFilter, ExportModal },
  setup() {
    const route = useRoute();
    let showModal = ref(false);
    let fieldsToSkip = ["SortBy", "SortOrder", "RoleCode", "ClientUserKey"];
    let filttable = ref("filttable");
    let Filter = reactive({
      Name: "",
      Email: "",

      ClientUserKey: route?.query?.key || "",
      RoleCode: "SUB_CLIENT",
      SortBy: "LastCreated",
      SortOrder: "descending",
    });

    let inviteData = reactive({
      Email: "",
      FirstName: "",
      LastName: "",
    });

    const addUser = () => {
      let keys = Object.keys(inviteData);
      for (let key of keys) {
        inviteData[key] = ""; //reset
      }
      showModal.value = true;
    };

    const inviteClient = async () => {
      let res = await post("/user/sub/invite", {
        ...inviteData,
        ClientUserKey: route?.query?.key || "",
      });
      if (isValidResponse(res)) {
        showModal.value = false;
        helpers.showMessage({
          Code: "MSG_EMAIL_INVIT_SENT",
          Message: "MSG_EMAIL_INVIT_SENT",
        });
      }
    };

    const handleDelete = async (index, data) => {
      try {
        let confirmed = await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/user/sub/remove", { UserKey: data.UserKey });
        if (!isValidResponse(res)) return;

        filterAction();
      } catch (err) {
        //err
      }
    };

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1, Filter);
    };

    const sortChange = ({ prop, order }) => {
      Filter.SortBy = prop;
      Filter.SortOrder = order;
      filterAction();
    };

    const filterNow = (page, filter = null) => {
      filttable.value.handleCurrentChange(page, filter);
    };

    const resetParams = () => {
      for (let key in Filter) {
        if (fieldsToSkip.indexOf(key) > -1) continue;
        Filter[key] = "";
      }
      filterNow(1, Filter);
    };

    const exportUser = ($refs) => {
      let FilterTable = { ...Filter, Filter: $refs?.filttable?.Filter?.Filter || "" };

      $refs.export.exportContent({
        Mode: "GENERATE_CLIENT",
        Filter: FilterTable,
        ExcelOnly: true,
      });
    };

    const handleOptions = async (row, cmd, $refs) => {
      if (cmd == "remove") {
        await handleDelete(0, row);
      } else if (cmd == "edit") {
        router.changeRoute(`/child/edit/${row.UserKey}`);
      }
    };

    return {
      inviteClient,
      showModal,
      inviteData,
      exportUser,
      filterAction,
      filterNow,
      sortChange,
      Filter,
      filttable,
      handleOptions,
      resetParams,
      addUser,
    };
  },
};
</script>
<style></style>
