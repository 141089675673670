<template>
  <el-popover v-if="hasSelection" placement="bottom" :width="300" trigger="click">
    <template #reference>
      <a href="javascript:;"
        >{{ fieldName }}

        <i
          class="fas fa-filter"
          v-if="IsHaveSelected"
          style="font-size: 11px; margin-right: 3px"
        ></i>
        <i class="fas fa-chevron-down"></i
      ></a>
    </template>

    <div class="table-selection-pop" v-loading="$h.isFetching()">
      <el-input placeholder="Search" v-model="FormData.SearchField"></el-input>
      <el-table
        ref="table"
        @selection-change="selectedArr"
        :row-key="(row) => row.RowKey"
        :data="renderData"
        max-height="300"
      >
        <el-table-column reserve-selection type="selection" width="55" />
        <el-table-column :label="$t.translate('LBL_ALL')">
          <template #default="scope">
            <span v-if="fieldKey == 'SubsAmount'">
              {{ $h.formatPrice(scope.row.Label) }}
            </span>
            <span v-else> {{ $t.translate(scope.row.Label) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-popover>
  <span v-else>{{ fieldName }}</span>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";

export default {
  props: {
    fieldLang: Boolean,
    selected: Object,
    handleSelection: Function,
    hasSelection: Boolean,
    fieldName: String,
    fieldKey: String,
    fieldLabel: String,
    url: String,
  },
  setup(props) {
    let table = ref("table");
    let loaded = ref(false);
    let IsService = ref(props.url.indexOf("assessment/list") > -1 ? true : false);

    let FormData = reactive({
      SearchField: "",
    });

    let Items = ref([]);
    let SelectedItems = ref([]);

    let IsHaveSelected = computed(() => {
      return SelectedItems.value.length ? true : false;
    });

    const renderData = computed(() => {
      let arr = _.orderBy(Items.value, ["Label"], ["asc"]);

      if (FormData.SearchField) {
        arr = _.filter(arr, (r) => {
          return r.Label.toLowerCase().indexOf(FormData.SearchField.toLowerCase()) > -1;
        });
      }
      return arr;
    });

    const handleCurrentChange = async () => {
      if (props.url.indexOf("envconf") > -1) {
        let d = props.url.replace("envconf/", "");
        let arr = store.getters["config/getData"]?.envConf?.[d] || [];
        Items.value = _.map(arr, (r) => {
          return {
            Label: r[props.fieldLabel],
            RowKey: r[props.fieldKey],
          };
        });
        return;
      }

      let res = await get(props.url, { IsHaveSubmission: 1, page: 1, size: 99999 });
      if (!isValidResponse(res)) return;
      Items.value = _.map(res?.List || res?.data || res?.RoleList || [], (r) => {
        let obj = {
          Label: props.fieldLang
            ? helpers.formatName(r, props.fieldLabel)
            : r[props.fieldLabel],
          RowKey: r[props.fieldKey],
        };

        if (props.fieldKey == "RoleCode") {
          obj.Label = `LBL_ROLE_${r[props.fieldKey]}`;
        }

        if (IsService.value) {
          obj.Label = helpers.formatServiceName(r);
        }
        return obj;
      });
    };

    onMounted(async () => {
      await handleCurrentChange();
      setTimeout(() => {
        let checkArr = [];
        if (props.selected && props.selected[props.fieldKey]) {
          checkArr = props.selected[props.fieldKey];
        }

        for (let row of Items.value) {
          let found = _.find(checkArr, (r) => r == row.RowKey);
          if (found) {
            table.value.toggleRowSelection(row, true);
          }
        }

        loaded.value = true;
      });
    });

    const selectedArr = (arr) => {
      if (!loaded.value) return;

      SelectedItems.value = arr;

      if (props?.handleSelection) {
        props.handleSelection(arr);
      }
    };

    return {
      IsService,
      table,
      IsHaveSelected,
      selectedArr,
      FormData,
      renderData,
    };
  },
};
</script>
